import { render, staticRenderFns } from "./run-ref.vue?vue&type=template&id=bb44d618&scoped=true&"
import script from "./run-ref.vue?vue&type=script&lang=js&"
export * from "./run-ref.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb44d618",
  null
  
)

export default component.exports