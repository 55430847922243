<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
    />
    <div class="d-flex flex-column flex-nowrap">
      <div>
        <span class="mr-1">{{$t('t.Number#')}}</span>
        <span class="mr-1">{{number}}</span>
        <span class="mr-1">{{$t('t.At')|lowercase}}</span>
        <calendar-date
          :date="date"
          :showTime="true"
        />
      </div>
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    CalendarDate: () => import('@/components/calendar-date')

  },
  computed: {
    number () {
      return this.id ? this.cache?.doc?.number : this.$t('t.None')
    },
    date () {
      return this.cache?.doc?.date
    }
  },
  methods: {
  },
  data () {
    return {
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    mixed: Boolean,
    cacheType: Number,
    showIcon: Boolean,
    iconName: String
  }
}
</script>

<style lang="stylus" scoped></style>
